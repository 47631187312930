import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Land from "../../Assets/Gifs/land.gif";

export default function Home() {
  return (
    <>
      <div
        className="Home"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container">
          <div className="row row-of-home">
            <div
              className="col-lg-12 col-xl-7 col-1-home mx-auto"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <h1 className="header h1-1 fadein">
                  ELUSION
                  <br />
                  GAMES
                </h1>
                <h1 className="h1-2 fadein" style={{ color: "white" }}>
                  Discover the future of gaming
                </h1>
              </div>
            </div>

            <div
              className="col-lg-12 col-xl-5  mx-auto fadein"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="fadein">
                <img src={Land} className="land-motion" alt="Land" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
