import "./App.css";
import "animate.css";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/HomePage/Home";
import Partnerships from "./components/Partnerships/Partnerships";
import EdolasWallet from "./components/EdolasWallet/EdolasWallet";
import NftPage from "./components/NftPage/NftPage";
import VenusTracker from "./components/VenusTracker/VenusTracker";
import ElusionLand from "./components/ElusionLand/ElusionLand";
import MeetOurTeam from "./components/MeetOurTeam/MeetOurTeam";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./components/Contact/Contact";

function App() {
  useEffect(() => {
    const images = document.querySelectorAll(".fadein");
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4, // Adjust this threshold based on your preference
    };

    const observer = new IntersectionObserver(fadeInOnScroll, observerOptions);
    images.forEach((img) => observer.observe(img));

    return () => observer.disconnect();
  });

  const fadeInOnScroll = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }, 200); // Adjust the delay time as needed
      }
    });
  };

  return (
    <div className="App" id="home">
      <div className="below-app">
        <Navbar />
      </div>
      <div className="nav-home">
        <Home />
      </div>
      <Partnerships />
      <EdolasWallet />
      <NftPage />
      <VenusTracker />
      <ElusionLand />
      <MeetOurTeam />
      <Contact />
    </div>
  );
}

export default App;
