import React, { useState, useEffect } from "react";
import "./Navbar.css";
import LogoEls from "../../Assets/Images/Logo.png";
import Burger from "../../Assets/BurgerMenu/menu.png";

export default function Navbar() {
  const [isScrolling, setIsScrolling] = useState(false);
  const [atTop, setAtTop] = useState(true);
  let scrollingTimeout;

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop < 50) {
      setAtTop(true);
      setIsScrolling(false);
    } else {
      setAtTop(false);
      setIsScrolling(true);
      clearTimeout(scrollingTimeout);
      scrollingTimeout = setTimeout(() => setIsScrolling(false), 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollingTimeout);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  const isNavbarCollapsed = () => {
    const navbarElement = document.getElementById("navbarNav");
    return navbarElement && navbarElement.classList.contains("show");
  };

  const navbarVisibilityClass =
    isScrolling || atTop || isNavbarCollapsed() ? "show" : "hide";

  return (
    <>
      <div className={`nav ${navbarVisibilityClass}`}>
        <nav
          className={`navbar navbar-expand-lg navbar-light container fadein`}
        >
          <img
            src={LogoEls}
            alt="Logo"
            className="navbar-brand "
            style={{ width: "180px" }}
          />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ backgroundColor: "transparent", border: "none" }} // Added inline styles
          >
            <span className="">
              <img src={Burger} alt="Menu" style={{ width: "33px" }} />
            </span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav" style={{ gap: "20px" }}>
              <li>
                <a onClick={() => scrollToSection("home")}>
                  <h2 className="nav-h2">About us</h2>
                </a>
              </li>
              <li>
                <a
                  href="https://elusiongames.gitbook.io/elusion-whitepaper-1.0/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <h2 className="nav-h2">Whitepaper</h2>
                </a>
              </li>
              <li>
                <a
                  href="https://elusiongames.gitbook.io/elusion-whitepaper-1.0/overview/roadmap"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <h2 className="nav-h2">Roadmap</h2>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
