import "./EdolasWallet.css";
import EdolasWalletImg from "../../Assets/Edolas/EdolasWallet.png";
import googlePlay from "../../Assets/DownloadApp/googleplay.png";
import appStore from "../../Assets/DownloadApp/appstore.png";

export default function EdolasWallet() {
  return (
    <>
      <div className="EdolasWallet" id="EdolasId">
        <div className="container container-of-edolas">
          <div className="row row-of-edolas">
            <div
              className="col-xl-8 "
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="mx-auto ">
                <h1
                  className="header-of-edolas fadein"
                  style={{ paddingBottom: "25px" }}
                >
                  Step into the
                  <br />
                  world of Web3
                </h1>
                <h4
                  className=" fadein"
                  style={{
                    color: "white",
                    paddingTop: "25px",
                    paddingBottom: "40px",
                  }}
                >
                  secure and user-friendly cryptocurrency wallet
                </h4>
                <div className="fadein">
                  <img
                    src={appStore}
                    className="download-app"
                    style={{ paddingBottom: "20px" }}
                    alt="App Store"
                  />
                  <img
                    src={googlePlay}
                    className=" download-app"
                    style={{ paddingBottom: "20px" }}
                    alt="Google Play Store"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 fadein">
              <img
                src={EdolasWalletImg}
                className="Edolas-img"
                alt="Edolas Wallet"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
