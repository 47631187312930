import "./NftPage.css";
import AuroraGif from "../../Assets/Gifs/Aurora.gif";
import ExploreAurora from "../../Assets/AuroraNft/ExploreAurora.png";
export default function NftPage() {
  return (
    <>
      <div
        className="NftPage"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="NftPageId"
      >
        <div>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1 className="nft-Header text-center fadein">Meet Aurora</h1>
                  <h4 className="nft-text fadein">
                    The colorful and glowing icon of your crypto world and NFT
                    collection, making every piece a treasure in your vibrant
                    virtual world.
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={ExploreAurora}
                      className="img-button fadein"
                      alt="img button"
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div className="col-lg-6 col-12 mx-auto custom-container">
                <div className="div-of-aurora-gif">
                  <img
                    src={AuroraGif}
                    className="aurora-gif img-fluid fadein"
                    alt="Aurora Nft"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
