import "./ElusionLand.css";
import ElusionImg from "../../Assets/ElusionLand/landmotion.png";
import ElusionButton from "../../Assets/ElusionLand/ElusionButton.png";

export default function ElusionLand() {
  return (
    <>
      <div
        className="ElusionLand"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="ElusionLandId"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div>
                <h1 className="elusion-header text-center fadein">
                  Dive into the future of gaming
                </h1>
                <h4 className="elusion-text text-center fadein">
                  where a crypto game unfolds landscapes of innovation. Immerse
                  in a world that blends technology and adventure for a unique
                  gaming experience.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={ElusionButton}
                    className="img-button fadein"
                    alt="img button"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 "
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <img
                src={ElusionImg}
                className=" img-fluid elusion-land-img fadein"
                style={{ width: "100%", height: "auto" }}
                alt="Elusion Land"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
