import React from "react";
import "./MeetOurTeam.css"; // Ensure you have this CSS file with the required styles
import Kasi from "../../Assets/Team/Kasi.png";
import Dog from "../../Assets/Team/dog (1).jpg";
function Persona({ name, role, imageUrl, linkedInUrl }) {
  return (
    <div className="Persona__Wrapper " id="MeetOurTeamId">
      <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={imageUrl}
          width="180"
          alt={name}
          className="Persona__Image fadein"
        />
      </a>
      <h5 className="Persona__Name text-white fadein">{name}</h5>
      <p className="Persona__Role  text-white fadein">{role}</p>
    </div>
  );
}

function MeetOurTeam() {
  const personasData = [
    {
      name: "Kastriot Pacoli",
      role: "Founder & CEO",
      imageUrl:
        "https://cdn.discordapp.com/attachments/1175583844628434994/1178101583804911696/KASIPNG.png?ex=6574eb99&is=65627699&hm=12fbeda155dd48440ae4cbbd46fefdf3fa6a093e9c6f1f35d1fe91e68c2ab296&",
    },
    {
      name: "Skade",
      role: "Co-founder",
      imageUrl:
        "https://cdn.discordapp.com/attachments/1175583844628434994/1178099290405605487/APe-270.png?ex=6574e976&is=65627476&hm=1a27aea2546e25b0af82144debad2c45c7ac099661a379fbcbff80e2293d4370&",
    },
    {
      name: "Iceberg",
      role: "Marketing",
      imageUrl:
        "https://cdn.discordapp.com/attachments/1175583844628434994/1178099457854800034/CfzoZnFp_400x400.jpg?ex=6574e99e&is=6562749e&hm=10022ab7aad95038f5c320316e0852c36b5f563190a678899c21b664f7666967&",
    },
    {
      name: "XENNIKE",
      role: "2D/3D Artist",
      imageUrl:
        "https://cdn.discordapp.com/attachments/1175583844628434994/1178102283775516772/Facetune_02-06-2021-22-20-48.jpg?ex=6574ec40&is=65627740&hm=30350a0dd9beb50ae9e411801353fd89cfafa4001044807c97a2be990d9dd5c8&",
    },
    {
      name: "Ludwig",
      role: "Web Designer",
      imageUrl:
        "https://cdn.discordapp.com/attachments/1175583844628434994/1178103258829557820/467.jpg?ex=6574ed29&is=65627829&hm=b948c603297d43b83ebdb1ead9979589ab4b0af2cb3f938da4d0b8b4b9388b04&",
    },
    {
      name: "Soulles",
      role: "Social Media",
      imageUrl:
        "https://cdn.discordapp.com/attachments/1175583844628434994/1178103613889990717/469.jpg?ex=6574ed7d&is=6562787d&hm=66e9f41d180d8bf3118cfea8db0d89e5019a6bbade8090958b40b6beefaecb84&",
    },
  ];

  return (
    <div
      className="MeetOurTeam"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container ">
        <h1 className="header-of-Team">Meet Our Team</h1>
        <div className="MeetOurTeam__Container">
          {personasData.map((persona, index) => (
            <Persona key={index} {...persona} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MeetOurTeam;
