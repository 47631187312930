import "./VenusTracker.css";
import TrackerImg from "../../Assets/Tracker/tracker.png";
import LeaderboardImg from "../../Assets/Tracker/Leaderboard.png";

export default function VenusTracker() {
  return (
    <>
      <div className="VenusTracker" id="VenusTrackerId">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div>
                <h1 className="venus-header text-center fadein">
                  Venus Tracker
                </h1>
                <h4 className="venus-text text-center fadein">
                  This tool enhances play-to-earn strategies by tracking in-game
                  activities, rewards, and achievements. It provides insights
                  for maximizing earnings and features a leaderboard ranking.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={LeaderboardImg}
                    className="img-button fadein"
                    alt="img button"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 "
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <img
                src={TrackerImg}
                className="tracker-img img-fluid fadein"
                alt="Game Tracker"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
