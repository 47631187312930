import React from "react";
import Logo from "../../Assets/Images/Logo.png";
import "./Contact.css";

const Contact = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div className="Contact">
        <div className="container container-of-contact">
          <div className="row">
            <div className="col-lg-6 col-12 div-1-of-contacts">
              <img
                src={Logo}
                alt="Logo"
                className="elusion-logo-contact img-fluid fadein"
              />
              <h6
                className="text-of-contact fadein"
                style={{ padding: "30px", paddingLeft: "10px" }}
              >
                An ultimate gaming destination for gamers, investors on the Web3
                <br />
                <br />
                Dream, Play, Evolve: Enter the Elusion
              </h6>
            </div>
            <div className="col-lg-6 col-12 div-2-of-contacts">
              <div className="quick-links">
                <div>
                  <h5
                    className="text-white fadein"
                    style={{ paddingTop: "20px", paddingBottom: "10px" }}
                  >
                    Quick Links
                  </h5>
                </div>
                <div className="quick-1 quick fadein">
                  <a onClick={() => scrollToSection("home")}>Home</a>
                </div>
                <div className="quick-2 quick fadein">
                  <a onClick={() => scrollToSection("PartnershipsId")}>
                    Partnerships
                  </a>
                </div>
                <div className="quick-3 quick fadein">
                  <a onClick={() => scrollToSection("EdolasId")}>
                    Edolas Wallet
                  </a>
                </div>
                <div className="quick-4 quick fadein">
                  <a onClick={() => scrollToSection("NftPageId")}>Aurora</a>
                </div>
                <div className="quick-5 quick fadein">
                  <a onClick={() => scrollToSection("VenusTrackerId")}>
                    Venus Tracker
                  </a>
                </div>
                <div className="quick-6 quick fadein">
                  <a onClick={() => scrollToSection("ElusionLandId")}>
                    Elusion Land
                  </a>
                </div>
                <div className="quick-7 quick fadein">
                  <a onClick={() => scrollToSection("MeetOurTeamId")}>
                    Meet Our Team
                  </a>
                </div>
              </div>
              <div className="Community">
                <h5
                  className="text-white fadein"
                  style={{ paddingTop: "20px", paddingBottom: "10px" }}
                >
                  Community
                </h5>
                <div className="community-a fadein">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://twitter.com/elusioncoin"
                    style={{ textDecoration: "none", color: "#616d7e" }}
                  >
                    Twitter
                  </a>
                </div>
                <div className="community-a fadein">
                  <a
                    href="https://t.me/ElusionGames"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#616d7e" }}
                  >
                    Telegram
                  </a>
                </div>
                <div className="community-a fadein">
                  <a
                    href="https://discord.gg/Yw2HTCPRqM"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#616d7e" }}
                  >
                    Discord
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
