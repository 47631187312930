import "./Partnerships.css";
import cg from "../../Assets/Partnerships/cg.png";
import cmc from "../../Assets/Partnerships/cmc.png";
import dex from "../../Assets/Partnerships/dex.png";
import gamefi from "../../Assets/Partnerships/gamefi.png";
import nuls from "../../Assets/Partnerships/nuls.png";
import pinksale from "../../Assets/Partnerships/pinksale.png";
import uniswap from "../../Assets/Partnerships/uniswap.png";
export default function Partnerships() {
  return (
    <>
      <div className="Partnerships" id="PartnershipsId">
        <div className="container container-of-partnerships">
          <div className="image-grid-container mx-auto">
            <div className="mx-auto fadein">
              <img src={cg} alt="CG" />
            </div>
            <div className="mx-auto fadein">
              <img src={cmc} alt="CMC" />
            </div>
            <div className="mx-auto fadein">
              <img src={dex} alt="DEX" />
            </div>
            <div className="mx-auto fadein">
              <img src={gamefi} alt="GameFi" />
            </div>
            <div className="mx-auto fadein">
              <img src={pinksale} alt="PinkSale" />
            </div>
            <div className="mx-auto fadein">
              <img src={uniswap} alt="uniswap" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
